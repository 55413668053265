.footer {
  position: relative;
  padding-top: 38px;
  overflow: hidden;

  .footer__circle {
    width: 560px;
    height: 560px;
    border-radius: 560px;
    border: 150px solid $brand-orange;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(40%, -40%);

    @include mq($until: laptop-m) {
      transform: translate(45%, -45%) scale(0.5);
    }
  }

  font-size: 16px;
  line-height: 20px;
  @include letter-spacing(20);
  color: $brand-white;
  text-decoration: none;
  @include font-weight(light);

  p,
  a:not(.btn) {
    font-size: 16px;
    line-height: 20px;
    @include letter-spacing(20);
    color: $brand-white;
    text-decoration: none;
    @include font-weight(light);
  }

  .footer__top {
    position: relative;
    margin-bottom: 54px;

    svg {
      display: block;
      height: 52px;
    }

    p {
      margin-top: 22px;
      @include font-weight(bold);
    }
  }

  .footer__middle {
    margin-bottom: 45px;
    color: $brand-white;

    .grid-column {
      @include mq($until: tablet) {
        margin-bottom: 40px;
      }
    }

    p:last-of-type {
      margin-bottom: 54px;
    }

    .footer__social {
      display: flex;

      @include mq(laptop-m) {
        justify-content: flex-end;
        align-items: flex-end;
      }

      .footer__social-link {
        height: 25px;
        display: flex;
        align-items: center;

        @include mq($until: tablet) {
          margin-right: 12px;
        }

        @include mq(tablet) {
          margin-left: 12px;
        }

        a {
          display: block;
          line-height: 0;
        }
      }
    }
  }

  .footer__bottom {
    padding-bottom: 65px;

    .grid-column {
      padding-top: 45px;
      border-top: 0.5px solid $brand-white;

      @include mq(footer) {
        display: flex;
        justify-content: space-between;
      }
    }

    p {
      font-size: 14px;
    }
  }

  .footer__nav {
    + .footer__nav {
      margin-top: 70px;

      @include mq(tablet) {
        margin-top: 54px;
      }
    }

    a {
      transition: color 0.3s ease-in-out;

      &:hover {
        color: $brand-orange;
      }
    }

    li {
      display: block;

      &:first-child {
        a,
        span {
          @include font-weight(bold);
        }

        span {
          cursor: default;
        }
      }

      & + li {
        margin-top: 15px;
      }
    }

    &--aux {
      ul {
        display: flex;
        flex-wrap: wrap;

        li {
          + li {
            margin-top: 0;

            @include mq($until: mobile-l) {
              margin-bottom: 10px;
            }

            @include mq(mobile-l) {
              margin-left: 10px;
            }

            @include mq(laptop-m) {
              margin-left: 30px;
            }
          }

          &:first-child {
            a {
              @include font-weight(light);
            }
          }

          @include mq($until: mobile-l) {
            min-width: 33%;
          }

          @include mq($until: mobile-m) {
            min-width: 50%;
          }
        }

        a {
          font-size: 14px;
        }
      }
    }
  }

  .footer__logo {
    max-width: 150px;
  }

  .footer__products {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;

    .footer__product-item {
      display: flex;
      align-items: flex-start;
      width: 66%;
      padding: 30px 0;

      @include mq(tablet) {
        min-width: 320px;
      }

      @include mq($until: laptop-m) {
        width: 100%;
      }

      &:first-child {
        padding: 0 0 30px 0;
        border-bottom: 1px solid $brand-white;
      }

      &--logo {
        width: 100%;
        max-width: 128px;
        min-width: 128px;
        margin-right: 50px;
      }

      &--content {
        color: $brand-white;
        font-size: 12px;
        @include font-weight(medium);
        max-width: 170px;
      }
    }
  }
}
