.ibnsoft-main {
  opacity: 1;
}

// reset - clean slate

@import 'plugin/plugin-tiny-slider';

// settings
@import 'settings/vars';

// functions/helpers/mixins
@import 'helpers/animations';
@import 'helpers/mixins';

// tools
@import '~plyr/src/sass/plyr';

// base
@import 'base/footer';

// forms
@import 'base/forms/index';
@import 'base/forms/input';
@import 'base/forms/radio-check';

// components - add components alphabetically
@import 'components/card';
@import 'components/card-blog';
@import 'components/card-content';
@import 'components/card-date';
@import 'components/card-event';
@import 'components/card-features';
@import 'components/card-fifty-fifty';
@import 'components/card-logo';
@import 'components/card-pricing';
@import 'components/card-project';
@import 'components/card-report';
@import 'components/card-stat';
@import 'components/card-testimonial';
@import 'components/card-webinar';
@import 'components/cards-portrait';
@import 'components/cards-landscape';
@import 'components/carousels';
@import 'components/carousel-course';
@import 'components/carousel-event';
@import 'components/case-study-card';

@import 'components/accordion';
@import 'components/alert';
@import 'components/bullet-list';
@import 'components/card-grid';
@import 'components/cookie-bar';
@import 'components/cta';
@import 'components/double-image';
@import 'components/features-list';
@import 'components/filter-bar';
@import 'components/header-section';
@import 'components/latest-news';
@import 'components/launch-banner';
@import 'components/listings';
@import 'components/pagination';
@import 'components/partner-card';
@import 'components/partnership-banner';
@import 'components/pricing-list';
@import 'components/product-overview';
@import 'components/rich-content';
@import 'components/share';
@import 'components/sitemap';
@import 'components/success-story';
@import 'components/stats-block';
@import 'components/tabbed-content-features';
@import 'components/tabbed-section';
@import 'components/tabbed-video';
@import 'components/ticker';
@import 'components/timeline';
@import 'components/trust-metrics';
@import 'components/video-card';
