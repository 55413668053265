@import '~sass-mq/mq';
// Enable media queries support
$mq-responsive: true;

// Defines breakpoints list
$mq-breakpoints: (
  mobile: 375px,
  mobile-m: 430px,
  mobile-l: 550px,
  tablet: 768px,
  laptop-s: 1024px,
  laptop-m: 1190px,
  desktop-s: 1366px,
  desktop: 1494px,
  footer: 1080px,
);

// Padding on either side of the main container.
$site-wide-padding: 30px;

// Width of the main container.
$desktop-width: map-get($mq-breakpoints, 'desktop');
$site-width: $desktop-width + ($site-wide-padding * 2);

$layer-root: -1;
$layer-canvas: 2;
$layer-overlay: 100;

// add any site specific layer values
$layer-cookies: 110;
$layer-nav: 99;

$brand-black: hsl(345, 6%, 13%);
$brand-black-80: hsl(345, 6%, 20);
$brand-black-70: hsl(345, 6%, 30);
$brand-black-60: hsl(345, 6%, 40);
$brand-black-50: hsl(345, 6%, 50);
$brand-black-40: hsl(345, 6%, 60);
$brand-black-30: hsl(345, 6%, 70);
$brand-black-25: hsl(345, 6%, 75);
$brand-black-20: hsl(345, 6%, 80);
$brand-black-10: hsl(345, 6%, 90);
$brand-black-05: hsl(330, 8%, 95%);
$brand-black-02: hsl(0, 0%, 98%);

$brand-blue: #3d5dc0;
$brand-blue-05: rgba($brand-blue, 0.05);
$brand-blue-dark: darken(desaturate($brand-blue, 1.78), 8.04);

$brand-green: #30a889;
$brand-green-dark: darken(desaturate($brand-green, 1.78), 8.04);

$brand-orange: #f05a22;
$brand-orange-dark: darken(desaturate($brand-orange, 1.78), 8.04);

$brand-purple: #8947a6;
$brand-purple-dark: darken(desaturate($brand-purple, 1.78), 8.04);

$brand-red: #c02e2e;

$brand-bronze: #b07d2f;
$brand-silver: #007dbb;
$brand-gold: #fbb900;

$brand-white: #ffffff;

$brand-text: $brand-black;
$error-text: #b72929;

$brand-shadow: rgba(0, 0, 0, 0.16);

$brand-font-primary: 'GT Walsheim', Helvetica, Arial, sans-serif;

$font-weight: (
  light: 100,
  regular: 400,
  medium: 500,
  bold: 700,
);

$border-radius-value: (
  small: 16px 4px 8px 4px,
  large: 8px 24px 8px 24px,
  xlarge: 40px 8px 20px 8px,
  medReverse: 8px 24px,
  checkbox: 8px 8px 4px 8px,
  metrics: 24px 24px 16px 16px,
);