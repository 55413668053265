.form-input,
.form-control {
  width: 100%;
  padding-top: 16px;
  padding-right: 32px;
  padding-bottom: 16px;
  padding-left: 32px;
  margin-top: 12px;
  border: 1px solid $brand-black-25;
  border-radius: 4px;
  font-family: $brand-font-primary;
  font-size: 14px;
  color: $brand-black;
  line-height: 16px;
  @include letter-spacing(20);
  outline: 0;
  appearance: none;
  transition: border-color 0.3s ease-out, color 0.3s ease-out;
  resize: none;
  overflow: hidden;
  background-color: $brand-white;

  &--text,
  &[type='text'],
  &[type='email'],
  &[type='file'] {
    @include border-radius(small);
  }

  &--textarea {
    height: 96px;
  }

  &--select {
    background-image: url('~@img/svg/icon-chevron-down.svg');
    background-repeat: no-repeat;
    background-size: 12px 8px;
    background-position: calc(100% - 20px) center;
    border-radius: 16px 4px 8px 4px;

    ~ .form-label--error {
      &:before {
        top: -42px;
      }
    }
  }

  &::placeholder {
    color: $brand-black-50;
  }

  &:focus {
    border-color: $brand-blue;
    color: $brand-black;
  }

  &[disabled] {
    color: $brand-black-20;
    border-color: $brand-black-20;
    background-color: transparent;
    cursor: not-allowed;

    + .form-label {
      color: $brand-black-20;
    }
  }

  &.form-input--error {
    border-color: $brand-red;
    padding-left: 42px;

    &::placeholder {
      opacity: 1;
      color: $brand-red;
    }

    ~ .form-label {
      display: block;
      color: $brand-red;
    }
  }
}

// KENTICO DEFAULT FORM FIELDS ONLY
textarea {
  &.form-control {
    @include border-radius(small);
    height: 96px;
  }
}

.editing-form-control-nested-control {
  select {
    background-image: url('~@img/svg/icon-chevron-down.svg');
    background-repeat: no-repeat;
    background-size: 12px 8px;
    background-position: calc(100% - 20px) center;
  }
  iframe {
    @include mq($until: mobile-l) {
      transform: scale(0.87);
      transform-origin: 0 0;
    }
  }
}

.ktc-uploader-text {
  margin-top: 10px;
  color: $brand-black;
}

.ktc-uploader-replacement {
  color: $brand-black;
  display: block;
}

.ktc-uploader-text-button {
  margin-top: 10px;
  display: block;
  color: $brand-black;
  cursor: pointer;
}

input[type='submit'] {
  --color-bg: #{$brand-blue};
  --color-bg-hover: #{$brand-blue-dark};
  --color-text: #{$brand-white};
  --color-text-hover: #{$brand-white};
  --color-border: #{transparent};

  &.btn--white-border {
    --color-border: #{$brand-white};
  }

  @include helpers-reset-button;

  appearance: none;

  display: inline-block;
  font-family: $brand-font-primary;
  font-size: 16px;
  @include font-weight(medium);
  text-align: center;
  text-decoration: none;
  padding-top: 15px;
  padding-right: 47px;
  padding-bottom: 15px;
  padding-left: 47px;
  border: 1px solid;
  border-radius: 24px;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    color 0.3s ease-in-out;

  background-color: var(--color-bg);
  border-color: var(--color-border);
  color: var(--color-text);

  &:hover {
    background-color: var(--color-bg-hover);
    border-color: var(--color-border-hover);
    color: var(--color-text-hover);
  }
}
